/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        //prepare the document ready
        $(document).ready(function() {

          //scroll up and down
          var step = 25;
          var scrolling = false;

          function scrollContent(direction) {
              var amount = (direction === "up" ? "-=1px" : "+=1px");
              $(".scrolling-content").animate({
                  scrollTop: amount
              }, 1, function () {
                  if (scrolling) {
                      scrollContent(direction);
                  }
              });
          }

          // Wire up events for the 'scrollUp' link:
          $("#scrollUp").bind("click", function (event) {
              event.preventDefault();
              // Animates the scrollTop property by the specified
              // step.
              $(".scrolling-content").animate({
                  scrollTop: "-=" + step + "px"
              });
          }).bind("mouseover", function (event) {
              scrolling = true;
              scrollContent("up");
          }).bind("mouseout", function (event) {
              scrolling = false;
          });


          $("#scrollDown").bind("click", function (event) {
              event.preventDefault();
              $(".scrolling-content").animate({
                  scrollTop: "+=" + step + "px"
              });
          }).bind("mouseover", function (event) {
              scrolling = true;
              scrollContent("down");
          }).bind("mouseout", function (event) {
              scrolling = false;
          });

          if ( ($(".inner-desc").length) && ($(".inner-desc")[0].scrollHeight <  $(".scrolling-content").innerHeight()) ) { // check if the scrolling content is overflowed
            $("#scrollUp").hide();
            $("#scrollDown").hide();
          }

          //end scroll up and down

          //initialise the custom scrollbar for products page
          $(window).on("load",function(){
            $(".tab-scrolling").mCustomScrollbar({
              axis:"x", // horizontal scrollbar
              theme:"light",
              advanced:{ autoExpandHorizontalScroll: true }
            });
          });

          //initialise the custom scrollbar for news page
          // $(".entry-content").mCustomScrollbar({
          //   axis:"y", // vertical scrollbar
          //   theme:"dark"
          // });

        });
        //end prepare document ready

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // History page
    'our_history': {
      init: function() {
        // Add scrollspy to <body>
        $('body').scrollspy({target: ".timeline-scrollbar", offset: 15});

        // Add smooth scrolling on all links inside the navbar
        $(".timeline-scrollbar a").on('click', function(event) {

          // Make sure this.hash has a value before overriding default behavior
          if (this.hash !== "") {

            // Prevent default anchor click behavior
            event.preventDefault();

            // Store hash
            var hash = this.hash;

            // Using jQuery's animate() method to add smooth page scroll
            // The optional number (800) specifies the number of milliseconds it takes to scroll to the specified area
            $('html, body').animate({
              scrollTop: $(hash).offset().top
            }, 800, function(){

            // Add hash (#) to URL when done scrolling (default click behavior)
              window.location.hash = hash;
            });

          } // End if

        });

      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
